import React, { useState, useEffect } from "react";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const [navbarHeight, setNavbarHeight] = useState();

  useEffect(() => {
    const navbar = document.getElementsByClassName("navbar2_component")[0];
    setNavbarHeight(navbar.offsetHeight);
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    agreeToTerms: false,
  });

  const [error, setError] = useState();

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    // Update the corresponding form field in formData
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Do something with the formData, e.g., send it to an API
    if (!formData.agreeToTerms) {
      setError(true);
    } else {
      const serviceId = "service_0436uqk";
      const templateId = "template_ms8ss3g";
      const publicKey = "_hktNJi7h2p4brqZP";

      emailjs
        .send(serviceId, templateId, formData, publicKey)
        .then((response) => {
          console.log("Form data submitted:", formData);
          setFormData({
            name: "",
            email: "",
            message: "",
            agreeToTerms: false,
          });
        })
        .catch((error) => {
          console.log("Error sending email: ", error);
        });
    }
  };
  return (
    <div className="page-wrapper" style={{ paddingTop: navbarHeight }}>
      <main className="main-wrapper">
        <section className="section_contact-us_contact-form">
          <div className="padding-global">
            <div className="container-small">
              <div className="padding-section-large">
                <div className="margin-bottom margin-large">
                  <div className="text-align-center">
                    <div className="max-width-large align-center">
                      <div className="margin-bottom margin-small">
                        <h2 className="color-dark">Get in Touch</h2>
                      </div>
                      <p className="color-dark text-size-medium">
                        Have a question? We're here to help!
                      </p>
                    </div>
                  </div>
                </div>
                <div className="max-width-medium align-center">
                  <div className="contact-us_contact-form_component w-form">
                    <form
                      id="wf-form-Contact-1-Form"
                      name="wf-form-Contact-1-Form"
                      data-name="Contact 1 Form"
                      onSubmit={handleSubmit}
                      className="contact-us_contact-form_form"
                    >
                      <div className="form-field-wrapper">
                        <label
                          htmlFor="Contact-1-Name"
                          className="color-dark field-label"
                        >
                          Name
                        </label>
                        <input
                          type="text"
                          className="form-input w-input"
                          maxLength={256}
                          name="name"
                          data-name="Contact 1 Name"
                          placeholder=""
                          id="Contact-1-Name"
                          required=""
                          value={formData.name}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="form-field-wrapper">
                        <label
                          htmlFor="Contact-1-Email"
                          className="color-dark field-label"
                        >
                          Email
                        </label>
                        <input
                          type="email"
                          className="form-input w-input"
                          maxLength={256}
                          name="email"
                          data-name="Contact 1 Email"
                          placeholder=""
                          id="Contact-1-Email"
                          required=""
                          value={formData.email}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="form-field-wrapper">
                        <label
                          htmlFor="Contact-1-Message"
                          className="color-dark field-label"
                        >
                          Message
                        </label>
                        <textarea
                          placeholder="Type your message..."
                          maxLength={5000}
                          id="Contact-1-Message"
                          name="message"
                          data-name="Contact 1 Message"
                          required=""
                          className="form-input is-text-area w-input"
                          value={formData.message}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="margin-bottom margin-xsmall">
                        <label
                          id="Contact-1-Checkbox"
                          className="w-checkbox form-checkbox"
                        >
                          <div className="w-checkbox-input w-checkbox-input--inputType-custom form-checkbox-icon" />
                          <input
                            type="checkbox"
                            id="Contact-1-Checkbox"
                            name="agreeToTerms"
                            data-name="Contact 1 Checkbox"
                            required=""
                            style={{
                              opacity: 0,
                              position: "absolute",
                              zIndex: -1,
                            }}
                            checked={formData.agreeToTerms}
                            onChange={handleInputChange}
                          />
                          <span
                            htmlFor="Contact-1-Checkbox"
                            className="color-dark form-checkbox-label text-size-small w-form-label"
                          >
                            I agree to the Terms
                          </span>
                        </label>
                      </div>
                      <button
                        type="submit"
                        data-wait="Please wait..."
                        id="w-node-f02235f1-a57b-f99d-a587-53adef4b9001-3f93ada5"
                        className="button w-button"
                      >
                        Send
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section_contact-us_contact">
          <div className="padding-global">
            <div className="container-large">
              <div className="padding-section-large">
                <div className="w-layout-grid contact-us_contact_component">
                  <div className="contact-us_contact_content-left">
                    <div className="max-width-large">
                      <div className="margin-bottom margin-small">
                        <h2 className="color-dark">Get in Touch</h2>
                      </div>
                      <p className="color-dark text-size-medium">
                        We'd love to hear from you. Contact us for all your
                        window treatment needs.
                      </p>
                    </div>
                  </div>
                  <div className="color-dark contact-us_contact_content-right">
                    <div className="w-layout-grid contact-us_contact_contact-list">
                      <div
                        id="w-node-f02235f1-a57b-f99d-a587-53adef4b901a-3f93ada5"
                        className="contact-us_contact_item"
                      >
                        <div className="contact-us_contact_item-icon-wrapper">
                          <div className="icon-embed-xsmall w-embed">
                            <svg
                              width=" 100%"
                              height=" 100%"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M20 4H4C2.897 4 2 4.897 2 6V18C2 19.103 2.897 20 4 20H20C21.103 20 22 19.103 22 18V6C22 4.897 21.103 4 20 4ZM20 6V6.511L12 12.734L4 6.512V6H20ZM4 18V9.044L11.386 14.789C11.5611 14.9265 11.7773 15.0013 12 15.0013C12.2227 15.0013 12.4389 14.9265 12.614 14.789L20 9.044L20.002 18H4Z"
                                fill="currentColor"
                              />
                            </svg>
                          </div>
                        </div>
                        <div className=" color-dark contact-us_contact_item-text-wrapper">
                          <div className="margin-bottom margin-xxsmall">
                            <h6>Email</h6>
                          </div>
                          <a href="" className="text-style-link">
                            stylishdecodesign@hotmail.com
                          </a>
                        </div>
                      </div>
                      <div
                        id="w-node-f02235f1-a57b-f99d-a587-53adef4b9023-3f93ada5"
                        className="contact-us_contact_item"
                      >
                        <div className="contact-us_contact_item-icon-wrapper">
                          <div className="icon-embed-xsmall w-embed">
                            <svg
                              width=" 100%"
                              height=" 100%"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M17.707 12.293C17.6142 12.2 17.504 12.1263 17.3827 12.076C17.2614 12.0257 17.1313 11.9998 17 11.9998C16.8687 11.9998 16.7386 12.0257 16.6173 12.076C16.496 12.1263 16.3858 12.2 16.293 12.293L14.699 13.887C13.96 13.667 12.581 13.167 11.707 12.293C10.833 11.419 10.333 10.04 10.113 9.30096L11.707 7.70696C11.7999 7.61417 11.8737 7.50397 11.924 7.38265C11.9743 7.26134 12.0002 7.13129 12.0002 6.99996C12.0002 6.86862 11.9743 6.73858 11.924 6.61726C11.8737 6.49595 11.7999 6.38575 11.707 6.29296L7.707 2.29296C7.61421 2.20001 7.50401 2.12627 7.38269 2.07596C7.26138 2.02565 7.13133 1.99976 7 1.99976C6.86866 1.99976 6.73862 2.02565 6.6173 2.07596C6.49599 2.12627 6.38579 2.20001 6.293 2.29296L3.581 5.00496C3.201 5.38496 2.987 5.90696 2.995 6.43996C3.018 7.86396 3.395 12.81 7.293 16.708C11.191 20.606 16.137 20.982 17.562 21.006H17.59C18.118 21.006 18.617 20.798 18.995 20.42L21.707 17.708C21.7999 17.6152 21.8737 17.505 21.924 17.3837C21.9743 17.2623 22.0002 17.1323 22.0002 17.001C22.0002 16.8696 21.9743 16.7396 21.924 16.6183C21.8737 16.4969 21.7999 16.3867 21.707 16.294L17.707 12.293ZM17.58 19.005C16.332 18.984 12.062 18.649 8.707 15.293C5.341 11.927 5.015 7.64196 4.995 6.41896L7 4.41396L9.586 6.99996L8.293 8.29296C8.17546 8.41041 8.08904 8.55529 8.04155 8.71453C7.99406 8.87376 7.987 9.04231 8.021 9.20496C8.045 9.31996 8.632 12.047 10.292 13.707C11.952 15.367 14.679 15.954 14.794 15.978C14.9565 16.0129 15.1253 16.0064 15.2846 15.9591C15.444 15.9117 15.5889 15.825 15.706 15.707L17 14.414L19.586 17L17.58 19.005V19.005Z"
                                fill="currentColor"
                              />
                            </svg>
                          </div>
                        </div>
                        <div className="contact-us_contact_item-text-wrapper">
                          <div className="margin-bottom margin-xxsmall">
                            <h6>Phone</h6>
                          </div>
                          <a href="" className="text-style-link">
                            +1 (555) 123-4567
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Contact;
