import React from "react";

const Terms = () => {
  return (
    <section className="section_faq7">
      <div className="padding-global">
        <div className="container-small">
          <div className="padding-section-large">
            <div className="margin-bottom margin-xxlarge">
              <div className="text-align-center">
                <div className="max-width-large">
                  <h2 className="color-dark">Terms &amp; Conditions</h2>
                </div>
              </div>
            </div>
            <div className="div-block">
              <div className="text-block-2 color-dark">
                Welcome to Importex's Terms and Conditions page. These terms and
                conditions outline your agreement with us when you access and
                use our website. By using our website, you agree to comply with
                and be bound by the following terms and conditions.
              </div>
            </div>
            <div className="faq7_component">
              <div className="faq7_item">
                <div className="margin-bottom margin-xsmall">
                  <div className="text-size-medium text-weight-bold">
                    <strong className="color-dark">Use of Website</strong>
                  </div>
                </div>
                <p className="color-dark">
                  1.1. The content on this website is for general information
                  and use only. It is subject to change without notice.
                  <br />
                  1.2. You acknowledge that the use of any information or
                  materials on this website is entirely at your own risk. We are
                  not liable for any consequences resulting from your use of
                  this website.
                </p>
              </div>
              <div className="faq7_item color-dark">
                <div className="margin-bottom margin-xsmall">
                  <div className="text-size-medium text-weight-bold">
                    <strong color-dark>Intellectual Property</strong>
                  </div>
                </div>
                <p>
                  2.1. This website contains material that is owned by or
                  licensed to us. This material includes, but is not limited to,
                  the design, layout, look, appearance, and graphics.
                  <br />
                  2.2. Reproduction is prohibited without our prior written
                  consent.
                </p>
              </div>
              <div className="faq7_item color-dark">
                <div className="margin-bottom margin-xsmall">
                  <div className="text-size-medium text-weight-bold">
                    <strong>Privacy</strong>
                  </div>
                </div>
                <p>
                  Your use of this website is governed by our Privacy Policy.
                  Please review this policy to understand our practices
                  concerning your personal information.
                </p>
              </div>
              <div className="faq7_item color-dark">
                <div className="margin-bottom margin-xsmall">
                  <div className="text-size-medium text-weight-bold">
                    <strong>Disclaimer</strong>
                  </div>
                </div>
                <p>
                  5.1. Your use of any information or materials on this website
                  is entirely at your own risk. It shall be your own
                  responsibility to ensure that any products, services, or
                  information available through this website meet your specific
                  requirements.
                  <br />
                  5.2. We make no warranty or representation regarding the
                  accuracy or suitability of the information on this website for
                  any particular purpose. You acknowledge that such information
                  may contain inaccuracies or errors, and we expressly exclude
                  liability for any such inaccuracies or errors to the fullest
                  extent permitted by law.
                </p>
              </div>
              <div className="faq7_item color-dark">
                <div className="margin-bottom margin-xsmall">
                  <div className="text-size-medium text-weight-bold">
                    <strong>Changes to Terms and Conditions</strong>
                  </div>
                </div>
                <p>
                  We may update these Terms and Conditions to reflect changes in
                  our practices or for legal and regulatory reasons. Please
                  review these terms periodically for updates.
                </p>
                <div className="div-block">
                  <div>
                    If you have any questions or concerns regarding these terms
                    and conditions, please contact us. Your use of our website
                    is subject to these terms, and we appreciate your
                    understanding and compliance.
                  </div>
                </div>
              </div>
            </div>
            <div className="margin-top margin-xxlarge">
              <div className="text-align-center">
                <div className="max-width-medium align-center">
                  <div className="margin-bottom margin-xsmall">
                    <h4 className="color-dark">Still have questions?</h4>
                  </div>
                  <p className="text-size-medium color-dark">
                    Contact us for further assistance.
                  </p>
                  <div className="margin-top margin-medium">
                    <a href="/contact" className="button is-secondary w-button">
                      Contact
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Terms;
