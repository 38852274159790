import React, { useState, useEffect } from "react";
import aboutImg from "../Assets/aboutImg.jpg";
import aboutImgHeader from "../Assets/aboutImgHeader.jpg";
import about1 from "../Assets/about1.png";
import about2 from "../Assets/about2.png";
import about3 from "../Assets/about3.png";
import about4 from "../Assets/about4.png";
const About = () => {
  const [navbarHeight, setNavbarHeight] = useState();

  useEffect(() => {
    const navbar = document.getElementsByClassName("navbar2_component")[0];
    setNavbarHeight(navbar.offsetHeight);
  }, []);
  return (
    <div className="page-wrapper" style={{ paddingTop: navbarHeight }}>
      <main className="main-wrapper">
        <header className="section_about-us_header">
          <div className="padding-global">
            <div className="container-large">
              <div className="padding-section-large">
                <div className="max-width-large">
                  <div className="margin-bottom margin-small">
                    <h1 className="color-white">About Stylish Deco Design</h1>
                  </div>
                  <p className="text-size-medium color-white">
                    Stylish Deco Design is a prominent window treatment company,
                    specializing in curtains, blinds, shades, drapes, and
                    elegant plantation shutters.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="about-us_header_background-image-wrapper">
            <div className="image-overlay-layer" />
            <img
              src={aboutImgHeader}
              loading="eager"
              alt=""
              className="about-us_header_background-image"
            />
          </div>
        </header>
        <section className="section_about-us_about">
          <div className="padding-global">
            <div
              data-w-id="8962e995-da0e-7637-b4b3-fea8e1ef2453"
              style={{ opacity: 0 }}
              className="top-to-bot-reveal"
            >
              <div className="container-large">
                <div className="padding-section-large">
                  <div className="margin-bottom margin-xxlarge">
                    <div className="w-layout-grid about-us_about_component">
                      <div className="about-us_about_content-left">
                        <h2 className="color-dark">
                          Our Journey: From Humble Beginnings to Success
                        </h2>
                      </div>
                      <div className="about-us_about_content-right">
                        <p className="color-dark text-size-medium">
                          Stylish Deco Design was born with a vision to
                          transform the window treatment industry. From our
                          humble beginnings, we've steadily evolved, building a
                          reputation founded on exceptional craftsmanship and
                          unmatched customer service. What sets us apart is our
                          unwavering commitment to using the finest Australian
                          materials. With numerous successful projects behind
                          us, we remain dedicated to pushing boundaries and
                          establishing new industry standards while showcasing
                          the quality of Australian materials in every product
                          we deliver.
                        </p>
                        <div className="margin-top margin-medium">
                          <div className="button-group">
                            <a
                              href="/services"
                              className="button is-secondary w-button"
                            >
                              Learn More
                            </a>
                            <a
                              href="/contact"
                              className="button is-link is-icon w-inline-block"
                            >
                              <div className="color-dark">Contact Us</div>
                              <div className="icon-embed-xxsmall w-embed">
                                <svg
                                  width={16}
                                  height={16}
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M6 3L11 8L6 13"
                                    stroke="#10256a"
                                    strokeWidth="1.5"
                                  />
                                </svg>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {window.innerWidth > 768 && (
                    <div className="about-us_about_image-wrapper">
                      <img
                        src={aboutImg}
                        loading="lazy"
                        alt=""
                        className="about-us_about_image"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section_about-us_benefits">
          <div className="padding-global">
            <div
              data-w-id="d648ef7e-408f-b50d-07ac-530bff0d7aba"
              style={{ opacity: 0 }}
              className="top-to-bot-reveal"
            >
              <div className="container-large">
                <div className="padding-section-large">
                  <div
                    data-w-id="06bed853-73b4-055e-815b-0f30422f3bab"
                    style={{ opacity: 0 }}
                    className="w-layout-grid about-us_benefits_component"
                  >
                    <div className="about-us_benefits_content-left">
                      <div className="margin-bottom margin-small">
                        <h2 className="color-dark">
                          Experience the Best Window Treatment Services
                        </h2>
                      </div>
                      <p className="color-dark text-size-medium">
                        At Stylish Deco Design, we are committed to providing
                        top-notch window treatment services that enhance the
                        beauty and functionality of your space. With our wide
                        range of curtains, blinds, shades, drapes, and shutters,
                        you can find the perfect window treatments to suit your
                        style and needs. Our team of experts will guide you
                        through the selection process and ensure a seamless
                        installation.
                      </p>
                    </div>
                    <div
                      id="w-node-_06bed853-73b4-055e-815b-0f30422f3bbd-f16bd7ca"
                      className="about-us_benefits_content-right"
                    >
                      <div className="about-us_benefits_content-item content-item-1">
                        <div className="margin-bottom margin-xsmall">
                          <img
                            src={about1}
                            loading="lazy"
                            alt=""
                            className="icon-1x1-medium"
                          />
                        </div>
                        <div className="margin-bottom margin-xsmall">
                          <h5 className="color-dark">Expert Advice</h5>
                        </div>
                        <p className="color-dark">
                          Our experienced team is here to provide you with
                          personalized advice and recommendations, helping you
                          make the best choices for your window treatment needs.
                          We stay updated with the latest trends and
                          technologies in the industry to ensure that you
                          receive the highest quality products and services.
                        </p>
                      </div>
                      <div className="about-us_benefits_content-item content-item-2">
                        <div className="margin-bottom margin-xsmall">
                          <img
                            src={about2}
                            loading="lazy"
                            alt=""
                            className="icon-1x1-medium"
                          />
                        </div>
                        <div className="margin-bottom margin-xsmall">
                          <h5 className="color-dark">Custom Solutions</h5>
                        </div>
                        <p className="color-dark">
                          We understand that every space is unique, and that's
                          why we offer custom solutions tailored to your
                          specific requirements. Whether you need window
                          treatments for your home or office, our team will work
                          closely with you to design and create the perfect
                          solution that reflects your style and enhances the
                          functionality of your space.
                        </p>
                      </div>
                      <div className="about-us_benefits_content-item content-item-3">
                        <div className="margin-bottom margin-xsmall">
                          <img
                            src={about3}
                            loading="lazy"
                            alt=""
                            className="icon-1x1-medium"
                          />
                        </div>
                        <div className="margin-bottom margin-xsmall">
                          <h5 className="color-dark">Exceptional Service</h5>
                        </div>
                        <p className="color-dark">
                          At sylishdecodesign, we pride ourselves on delivering
                          exceptional service to our customers. From the moment
                          you contact us to the final installation, we will
                          ensure that your experience with us is smooth and
                          hassle-free. Our dedicated team is always ready to
                          assist you and provide the support you need throughout
                          the process.
                        </p>
                      </div>
                      <div className="about-us_benefits_content-item content-item-4">
                        <div className="margin-bottom margin-xsmall">
                          <img
                            src={about4}
                            loading="lazy"
                            alt=""
                            className="icon-1x1-medium"
                          />
                        </div>
                        <div className="margin-bottom margin-xsmall">
                          <h5 className="color-dark">Affordable Prices</h5>
                        </div>
                        <p className="color-dark">
                          We believe that high-quality window treatments should
                          be accessible to everyone. That's why we offer
                          competitive prices without compromising on the quality
                          of our products and services. With sylishdecodesign,
                          you can enjoy beautiful and functional window
                          treatments that fit your budget.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section_about-us_cta">
          <div className="padding-global">
            <div className="container-large">
              <div className="padding-section-large">
                <div
                  data-w-id="7d319e27-3ba0-eb25-ba62-ca343ad1cc79"
                  style={{ opacity: 0 }}
                  className="top-to-bot-reveal"
                >
                  <div className="w-layout-grid about-us_cta_component">
                    <div className="about-us_cta_card">
                      <div className="about-us_cta_content">
                        <div className="max-width-large">
                          <div className="margin-bottom margin-xsmall">
                            <h3 className="color-dark">
                              Transforming Windows with Elegant Treatments
                            </h3>
                          </div>
                          <p className="color-dark text-size-medium">
                            Discover the Perfect Window Treatments for Your Home
                          </p>
                        </div>
                      </div>
                      <div className="about-us_cta_button-row">
                        <a href="/contact" className="button w-button">
                          Contact
                        </a>
                        <a
                          href="/services"
                          className="button is-secondary w-button"
                        >
                          Learn More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default About;
