import React from "react";

const Faq = () => {
  return (
    <div className="page-wrapper">
      <main className="main-wrapper">
        <section className="section_faq_faq">
          <div className="padding-global">
            <div className="container-large">
              <div className="padding-section-large">
                <div className="w-layout-grid faq_faq_component">
                  <div className="faq_faq_content">
                    <div className="margin-bottom margin-small">
                      <h2 className="color-dark">Frequently Asked Questions</h2>
                    </div>
                    <p className="color-dark text-size-medium">
                      Find answers to common questions about window treatments,
                      selection, installation, and maintenance.
                    </p>
                    <div className="margin-top margin-medium">
                      <a
                        href="/contact"
                        className="button is-secondary w-button"
                      >
                        Contact
                      </a>
                    </div>
                  </div>
                  <div className="faq_faq_list">
                    <div className="faq_faq_accordion">
                      <div
                        data-w-id="3e52c1a7-75bd-a673-7b8e-f623dc08f763"
                        className="faq_faq_question"
                      >
                        <div className="color-dark text-size-medium text-weight-bold">
                          What types of window treatments do you offer?
                        </div>
                        <div className="faq_faq_icon-wrapper">
                          <div className="icon-embed-small w-embed">
                            <svg
                              width="100%"
                              height="100%"
                              viewBox="0 0 32 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M16.5303 20.8839C16.2374 21.1768 15.7626 21.1768 15.4697 20.8839L7.82318 13.2374C7.53029 12.9445 7.53029 12.4697 7.82318 12.1768L8.17674 11.8232C8.46963 11.5303 8.9445 11.5303 9.2374 11.8232L16 18.5858L22.7626 11.8232C23.0555 11.5303 23.5303 11.5303 23.8232 11.8232L24.1768 12.1768C24.4697 12.4697 24.4697 12.9445 24.1768 13.2374L16.5303 20.8839Z"
                                fill="#10256a"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{ width: "100%", height: 0 }}
                        className="faq_faq_answer"
                      >
                        <div className="margin-bottom margin-small">
                          <p className="color-dark">
                            We offer a wide range of window treatments including
                            curtains, blinds, shades, drapes, and shutters. Our
                            team can help you choose the best option for your
                            needs.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="faq_faq_accordion">
                      <div
                        data-w-id="3e52c1a7-75bd-a673-7b8e-f623dc08f76d"
                        className="faq_faq_question"
                      >
                        <div className="color-dark text-size-medium text-weight-bold">
                          How do I select the right window treatment?
                        </div>
                        <div className="faq_faq_icon-wrapper">
                          <div className="icon-embed-small w-embed">
                            <svg
                              width="100%"
                              height="100%"
                              viewBox="0 0 32 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M16.5303 20.8839C16.2374 21.1768 15.7626 21.1768 15.4697 20.8839L7.82318 13.2374C7.53029 12.9445 7.53029 12.4697 7.82318 12.1768L8.17674 11.8232C8.46963 11.5303 8.9445 11.5303 9.2374 11.8232L16 18.5858L22.7626 11.8232C23.0555 11.5303 23.5303 11.5303 23.8232 11.8232L24.1768 12.1768C24.4697 12.4697 24.4697 12.9445 24.1768 13.2374L16.5303 20.8839Z"
                                fill="#10256a"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{ width: "100%", height: 0 }}
                        className="faq_faq_answer"
                      >
                        <div className="margin-bottom margin-small">
                          <p className="color-dark">
                            Selecting the right window treatment depends on
                            factors such as your style preferences, privacy
                            needs, and budget. Our experts can guide you through
                            the selection process.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="faq_faq_accordion">
                      <div
                        data-w-id="3e52c1a7-75bd-a673-7b8e-f623dc08f777"
                        className="faq_faq_question"
                      >
                        <div className="color-dark text-size-medium text-weight-bold">
                          Do you provide installation services?
                        </div>
                        <div className="faq_faq_icon-wrapper">
                          <div className="icon-embed-small w-embed">
                            <svg
                              width="100%"
                              height="100%"
                              viewBox="0 0 32 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M16.5303 20.8839C16.2374 21.1768 15.7626 21.1768 15.4697 20.8839L7.82318 13.2374C7.53029 12.9445 7.53029 12.4697 7.82318 12.1768L8.17674 11.8232C8.46963 11.5303 8.9445 11.5303 9.2374 11.8232L16 18.5858L22.7626 11.8232C23.0555 11.5303 23.5303 11.5303 23.8232 11.8232L24.1768 12.1768C24.4697 12.4697 24.4697 12.9445 24.1768 13.2374L16.5303 20.8839Z"
                                fill="#10256a"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{ width: "100%", height: 0 }}
                        className="faq_faq_answer"
                      >
                        <div className="margin-bottom margin-small">
                          <p className="color-dark">
                            Yes, we offer professional installation services to
                            ensure your window treatments are properly installed
                            and functioning correctly.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="faq_faq_accordion">
                      <div
                        data-w-id="3e52c1a7-75bd-a673-7b8e-f623dc08f781"
                        className="faq_faq_question"
                      >
                        <div className="color-dark text-size-medium text-weight-bold">
                          How do I maintain my window treatments?
                        </div>
                        <div className="faq_faq_icon-wrapper">
                          <div className="icon-embed-small w-embed">
                            <svg
                              width="100%"
                              height="100%"
                              viewBox="0 0 32 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M16.5303 20.8839C16.2374 21.1768 15.7626 21.1768 15.4697 20.8839L7.82318 13.2374C7.53029 12.9445 7.53029 12.4697 7.82318 12.1768L8.17674 11.8232C8.46963 11.5303 8.9445 11.5303 9.2374 11.8232L16 18.5858L22.7626 11.8232C23.0555 11.5303 23.5303 11.5303 23.8232 11.8232L24.1768 12.1768C24.4697 12.4697 24.4697 12.9445 24.1768 13.2374L16.5303 20.8839Z"
                                fill="#10256a"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{ width: "100%", height: 0 }}
                        className="faq_faq_answer"
                      >
                        <div className="margin-bottom margin-small">
                          <p className="color-dark">
                            Proper maintenance of window treatments can prolong
                            their lifespan. We provide care instructions and
                            tips to help you keep them in great condition.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="faq_faq_accordion">
                      <div
                        data-w-id="3e52c1a7-75bd-a673-7b8e-f623dc08f78b"
                        className="faq_faq_question"
                      >
                        <div className="color-dark text-size-medium text-weight-bold">
                          Can I get a free consultation?
                        </div>
                        <div className="faq_faq_icon-wrapper">
                          <div className="icon-embed-small w-embed">
                            <svg
                              width="100%"
                              height="100%"
                              viewBox="0 0 32 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M16.5303 20.8839C16.2374 21.1768 15.7626 21.1768 15.4697 20.8839L7.82318 13.2374C7.53029 12.9445 7.53029 12.4697 7.82318 12.1768L8.17674 11.8232C8.46963 11.5303 8.9445 11.5303 9.2374 11.8232L16 18.5858L22.7626 11.8232C23.0555 11.5303 23.5303 11.5303 23.8232 11.8232L24.1768 12.1768C24.4697 12.4697 24.4697 12.9445 24.1768 13.2374L16.5303 20.8839Z"
                                fill="#10256a"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{ width: "100%", height: 0 }}
                        className="faq_faq_answer"
                      >
                        <div className="margin-bottom margin-small">
                          <p className="color-dark">
                            Yes, we offer free consultations to discuss your
                            window treatment needs and provide personalized
                            recommendations. Contact us to schedule an
                            appointment.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Faq;
