import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Home from "./Pages/Home";
import Contact from "./Pages/Contact";
import Footer from "./Footer";
import About from "./Pages/About";
import Portfolio from "./Pages/Portfolio";
import Cookie from "./Pages/Cookie";
import Faq from "./Pages/Faq";
import Privacy from "./Pages/Privacy";
import Services from "./Pages/Services";
import Terms from "./Pages/Terms";

function App() {
  useEffect(() => {
    window.Webflow && window.Webflow.destroy();
    window.Webflow && window.Webflow.ready();
    window.Webflow && window.Webflow.require("ix2").init();
    document.dispatchEvent(new Event("readystatechange"));
  });

  return (
    <div className="App">
      <BrowserRouter>
        <Navbar></Navbar>

        <Routes>
          <Route path="/" element={<Home></Home>} />
          <Route path="/contact" element={<Contact></Contact>} />
          <Route path="/about" element={<About></About>} />
          <Route path="/portfolio" element={<Portfolio></Portfolio>} />
          <Route path="/cookies" element={<Cookie></Cookie>} />
          <Route path="/faq" element={<Faq></Faq>} />
          <Route path="/privacy" element={<Privacy></Privacy>} />
          <Route path="/services" element={<Services></Services>} />
          <Route path="/terms" element={<Terms></Terms>} />
        </Routes>

        <Footer></Footer>
      </BrowserRouter>
    </div>
  );
}

export default App;
