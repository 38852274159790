import React from "react";

const Cookie = () => {
  return (
    <section className="section_faq7 color-dark">
      <div className="padding-global">
        <div className="container-small">
          <div className="padding-section-large">
            <div className="margin-bottom margin-xxlarge">
              <div className="text-align-center">
                <div className="max-width-large">
                  <h2>Cookie Policy</h2>
                </div>
              </div>
            </div>
            <div className="div-block">
              <div className="text-block-2">
                Welcome to Importex's Cookies Policy. This policy explains how
                we use cookies on our website. By accessing or using our
                website, you consent to the use of cookies as described in this
                policy.
              </div>
            </div>
            <div className="faq7_component">
              <div className="faq7_item">
                <div className="margin-bottom margin-xsmall">
                  <div className="text-size-medium text-weight-bold">
                    <strong>What Are Cookies?</strong>
                  </div>
                </div>
                <p>
                  Cookies are small text files that are placed on your computer
                  or mobile device when you visit a website. They help websites
                  function more efficiently and provide valuable information to
                  website owners.
                </p>
              </div>
              <div className="faq7_item">
                <div className="margin-bottom margin-xsmall">
                  <div className="text-size-medium text-weight-bold">
                    <strong>Our Use of Cookies</strong>
                  </div>
                </div>
                <p>
                  We use cookies for the following purposes only:
                  <br />‍<strong>Essential Cookies:</strong> These cookies are
                  necessary for the basic functioning of our website, such as
                  enabling navigation and access to secure areas.
                  <br />‍<strong>Analytical Cookies:</strong> We use these
                  cookies to gather information about how visitors use our
                  website. This information helps us improve the website's
                  performance and user experience.
                </p>
              </div>
              <div className="faq7_item">
                <div className="margin-bottom margin-xsmall">
                  <div className="text-size-medium text-weight-bold">
                    <strong>Third-Party Cookies</strong>
                  </div>
                </div>
                <p>
                  We may use third-party services on our website, such as
                  analytics providers and social media platforms. These third
                  parties may also use cookies. We do not have control over the
                  cookies used by third-party services.
                </p>
              </div>
              <div className="faq7_item">
                <div className="margin-bottom margin-xsmall">
                  <div className="text-size-medium text-weight-bold">
                    <strong>Managing Cookies</strong>
                  </div>
                </div>
                <p>
                  You can control and manage cookies through your browser
                  settings. Please note that disabling cookies may affect your
                  browsing experience on our website.
                </p>
              </div>
              <div className="faq7_item">
                <div className="margin-bottom margin-xsmall">
                  <div className="text-size-medium text-weight-bold">
                    <strong>Changes to this Cookies Policy</strong>
                  </div>
                </div>
                <p>
                  We may update this Cookies Policy to reflect changes in our
                  cookie practices or for legal and regulatory reasons. We
                  encourage you to periodically review this policy for any
                  updates.
                </p>
                <div className="div-block">
                  <div>
                    If you have any questions or concerns about our use of
                    cookies, please feel free to contact us. Your privacy and
                    online experience are important to us, and we are committed
                    to ensuring transparency and a secure browsing experience on
                    our website.
                  </div>
                </div>
              </div>
            </div>
            <div className="margin-top margin-xxlarge">
              <div className="text-align-center">
                <div className="max-width-medium align-center">
                  <div className="margin-bottom margin-xsmall">
                    <h4>Still have questions?</h4>
                  </div>
                  <p className="text-size-medium">
                    Contact us for further assistance.
                  </p>
                  <div className="margin-top margin-medium">
                    <a href="/contact" className="button is-secondary w-button">
                      Contact
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cookie;
