import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../src/Assets/logo.svg";

const Navbar = () => {
  useEffect(() => {
    window.Webflow && window.Webflow.destroy();
    window.Webflow && window.Webflow.ready();
    window.Webflow && window.Webflow.require("ix2").init();
    document.dispatchEvent(new Event("readystatechange"));
  });
  const location = useLocation();
  const isActive = (path) => {
    return location.pathname === path;
  };
  return (
    <div
      data-animation="default"
      className="navbar2_component w-nav"
      data-easing2="ease"
      fs-scrolldisable-element="smart-nav"
      data-easing="ease"
      data-collapse="medium"
      data-w-id="1b976938-2990-825b-123f-4c8164da5b14"
      role="banner"
      data-duration={400}
    >
      <div className="navbar2_container">
        <a href="/" className="navbar2_logo-link w-nav-brand">
          <img src={logo} loading="lazy" alt="" className="navbar2_logo" />
        </a>
        <nav
          role="navigation"
          id="w-node-_1b976938-2990-825b-123f-4c8164da5b18-dd49243c"
          className="navbar2_menu is-page-height-tablet w-nav-menu"
        >
          <a
            href="/"
            className={`navbar2_link w-nav-link ${isActive("/") ? "bold" : ""}`}
          >
            Home
          </a>
          <a
            href="/about"
            className={`navbar2_link w-nav-link ${
              isActive("/about") ? "bold" : ""
            }`}
          >
            About
          </a>
          <a
            href="/services"
            className={`navbar2_link w-nav-link ${
              isActive("/services") ? "bold" : ""
            }`}
          >
            Services
          </a>
          <a
            href="/portfolio"
            className={`navbar2_link w-nav-link ${
              isActive("/portfolio") ? "bold" : ""
            }`}
          >
            Portfolio
          </a>
          <div
            data-hover="true"
            data-delay={200}
            data-w-id="1b976938-2990-825b-123f-4c8164da5b1f"
            className="navbar2_menu-dropdown w-dropdown"
          >
            <div className="navbar2_dropdwn-toggle w-dropdown-toggle">
              <div className="color-dark">More</div>
              <div className="dropdown-chevron w-embed">
                <svg
                  width=" 100%"
                  height=" 100%"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.55806 6.29544C2.46043 6.19781 2.46043 6.03952 2.55806 5.94189L3.44195 5.058C3.53958 4.96037 3.69787 4.96037 3.7955 5.058L8.00001 9.26251L12.2045 5.058C12.3021 4.96037 12.4604 4.96037 12.5581 5.058L13.4419 5.94189C13.5396 6.03952 13.5396 6.19781 13.4419 6.29544L8.17678 11.5606C8.07915 11.6582 7.92086 11.6582 7.82323 11.5606L2.55806 6.29544Z"
                    fill="#10256a"
                  />
                </svg>
              </div>
            </div>
            <nav
              data-w-id="1b976938-2990-825b-123f-4c8164da5b24"
              className="navbar2_dropdown-list w-dropdown-list"
            >
              <a
                href="/faq"
                className="color-dark navbar2_dropdown-link w-dropdown-link"
              >
                FAQ
              </a>
              <a
                href="/terms"
                className="color-dark navbar2_dropdown-link w-dropdown-link"
              >
                Terms & Conditions
              </a>
              <a
                href="/privacy"
                className="color-dark navbar2_dropdown-link w-dropdown-link"
              >
                Privacy Policy
              </a>

              <a
                href="/cookies"
                className="color-dark navbar2_dropdown-link w-dropdown-link"
              >
                Cookie Policy
              </a>
            </nav>
          </div>
        </nav>
        <div
          id="w-node-_1b976938-2990-825b-123f-4c8164da5b2b-dd49243c"
          className="navbar2_button-wrapper"
        >
          <a href="/contact" className="button is-navbar2-button w-button">
            Contact
          </a>
          <div className="navbar2_menu-button w-nav-button">
            <div className="menu-icon2">
              <div className="menu-icon2_line-top" />
              <div className="menu-icon2_line-middle">
                <div className="menu-icon_line-middle-inner" />
              </div>
              <div className="menu-icon2_line-bottom" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
