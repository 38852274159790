import React from "react";
import logo from "../src/Assets/logo.svg";

const Footer = () => {
  return (
    <footer className="footer7_component">
      <div className="padding-global">
        <div className="container-large">
          <div className="padding-vertical padding-xxlarge">
            <div className="padding-bottom padding-xxlarge">
              <div className="footer7_top-wrapper">
                <a
                  href="/"
                  id="w-node-_1b976938-2990-825b-123f-4c8164da5c55-dd49243c"
                  className="footer7_logo-link w-nav-brand"
                >
                  <img src={logo} loading="lazy" alt="" />
                </a>
                <div className="w-layout-grid footer7_link-list">
                  <a href="/about" className="footer7_link color-dark">
                    About Us
                  </a>
                  <a href="/services" className="footer7_link color-dark">
                    Services
                  </a>
                  <a href="/portfolio" className="footer7_link color-dark">
                    Portfolio
                  </a>
                  <a href="/contact" className="footer7_link color-dark">
                    Contact Us
                  </a>
                  <a href="/faq" className="footer7_link color-dark">
                    FAQ
                  </a>
                </div>
              </div>
            </div>
            <div className="line-divider" />
            <div className="padding-top padding-medium">
              <div className="footer7_bottom-wrapper">
                <div className="footer7_credit-text color-dark">
                  © 2023 Stylish Deco Design. All rights reserved.
                </div>
                <div className="w-layout-grid footer7_legal-list">
                  <a href="/privacy" className="color-dark footer7_legal-link">
                    Privacy Policy
                  </a>
                  <a href="/terms" className="footer7_legal-link color-dark">
                    Terms and Conditions
                  </a>
                  <a href="/cookie" className="footer7_legal-link color-dark">
                    Cookie Policy
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
