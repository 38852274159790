import React, { useEffect, useState } from "react";
import porfolioHeader from "../Assets/portfolioHeader.jpg";
import porfolioImg1 from "../Assets/portfolioImg1.jpg";
import porfolioImg2 from "../Assets/portfolioImg2.jpg";
import porfolioImg3 from "../Assets/portfolioImg3.jpg";
import porfolioImg4 from "../Assets/portfolioImg4.jpg";
import porfolioImg5 from "../Assets/portfolioImg5.jpg";
import porfolioImg6 from "../Assets/portfolioImg6.jpg";
import porfolioImg7 from "../Assets/portfolioImg7.jpg";
import porfolioImg8 from "../Assets/portfolioImg8.jpg";
import porfolioImg9 from "../Assets/portfolioImg9.jpg";
import porfolioImg10 from "../Assets/portfolioImg10.jpg";
import porfolioImg11 from "../Assets/portfolioImg11.jpg";
import porfolioImg12 from "../Assets/portfolioImg12.jpg";
import porfolioImg13 from "../Assets/portfolioImg13.jpg";
import porfolioImg14 from "../Assets/portfolioImg14.jpg";
import porfolioImg15 from "../Assets/portfolioImg15.jpg";
import porfolioImg16 from "../Assets/portfolioImg16.jpg";
import porfolioImg17 from "../Assets/portfolioImg17.jpg";
import porfolioImg18 from "../Assets/portfolioImg18.jpg";
const Portfolio = () => {
  const [navbarHeight, setNavbarHeight] = useState();

  useEffect(() => {
    const navbar = document.getElementsByClassName("navbar2_component")[0];
    setNavbarHeight(navbar.offsetHeight);
  }, []);
  return (
    <div className="page-wrapper" style={{ paddingTop: navbarHeight }}>
      <main className="main-wrapper">
        <header className="section_portfolio_header">
          <div className="padding-global">
            <div className="container-large">
              <div className="padding-section-large">
                <div className="max-width-large">
                  <div className="margin-bottom margin-small">
                    <h1 className="color-white">Expert Window Treatments</h1>
                  </div>
                  <p className="text-size-medium color-white">
                    Discover our wide range of high-quality window treatments,
                    including curtains, blinds, shades, drapes, and shutters.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="portfolio_header_background-image-wrapper">
            <div className="image-overlay-layer" />
            <img
              src={porfolioHeader}
              loading="eager"
              alt=""
              className="portfolio_header_background-image"
            />
          </div>
        </header>
        <section className="section_portfolio_gallery">
          <div className="padding-global">
            <div className="container-large">
              <div className="padding-section-large">
                <div className="margin-bottom margin-xxlarge">
                  <div className="text-align-center">
                    <div className="max-width-large align-center">
                      <div className="margin-bottom margin-small">
                        <h2 className="color-dark">Window Inspiration</h2>
                      </div>
                      <p className="color-dark text-size-medium">
                        Browse our stunning window treatment installations for
                        inspiration.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-layout-grid portfolio_gallery_component">
                  <div
                    data-w-id="488cecdd-4796-b7af-eb92-976f0ebf1814"
                    style={{ opacity: 0 }}
                    className="w-layout-grid portfolio_gallery_row"
                  >
                    <a
                      href="#"
                      id="w-node-_488cecdd-4796-b7af-eb92-976f0ebf1815-27f01313"
                      className="portfolio_gallery_lightbox-link w-inline-block w-lightbox"
                    >
                      <div className="portfolio_gallery_image-wrapper">
                        <img
                          src={porfolioImg1}
                          loading="lazy"
                          alt=""
                          className="portfolio_gallery_image"
                        />
                      </div>
                    </a>
                    <a
                      href="#"
                      id="w-node-_488cecdd-4796-b7af-eb92-976f0ebf1818-27f01313"
                      className="portfolio_gallery_lightbox-link w-inline-block w-lightbox"
                    >
                      <div className="portfolio_gallery_image-wrapper">
                        <img
                          src={porfolioImg2}
                          loading="lazy"
                          alt=""
                          className="portfolio_gallery_image"
                        />
                      </div>
                    </a>
                    <a
                      href="#"
                      id="w-node-_488cecdd-4796-b7af-eb92-976f0ebf181b-27f01313"
                      className="portfolio_gallery_lightbox-link w-inline-block w-lightbox"
                    >
                      <div className="portfolio_gallery_image-wrapper">
                        <img
                          src={porfolioImg3}
                          loading="lazy"
                          alt=""
                          className="portfolio_gallery_image"
                        />
                      </div>
                    </a>
                  </div>
                  <div
                    data-w-id="488cecdd-4796-b7af-eb92-976f0ebf181e"
                    style={{ opacity: 0 }}
                    className="w-layout-grid portfolio_gallery_row"
                  >
                    <a
                      href="#"
                      id="w-node-_488cecdd-4796-b7af-eb92-976f0ebf181f-27f01313"
                      className="portfolio_gallery_lightbox-link w-inline-block w-lightbox"
                    >
                      <div className="portfolio_gallery_image-wrapper">
                        <img
                          src={porfolioImg4}
                          loading="lazy"
                          alt=""
                          className="portfolio_gallery_image"
                        />
                      </div>
                    </a>
                    <a
                      href="#"
                      id="w-node-_488cecdd-4796-b7af-eb92-976f0ebf1822-27f01313"
                      className="portfolio_gallery_lightbox-link w-inline-block w-lightbox"
                    >
                      <div className="portfolio_gallery_image-wrapper">
                        <img
                          src={porfolioImg5}
                          loading="lazy"
                          alt=""
                          className="portfolio_gallery_image"
                        />
                      </div>
                    </a>
                    <a
                      href="#"
                      id="w-node-_488cecdd-4796-b7af-eb92-976f0ebf1825-27f01313"
                      className="portfolio_gallery_lightbox-link w-inline-block w-lightbox"
                    >
                      <div className="portfolio_gallery_image-wrapper">
                        <img
                          src={porfolioImg6}
                          loading="lazy"
                          alt=""
                          className="portfolio_gallery_image"
                        />
                      </div>
                    </a>
                  </div>
                  <div
                    data-w-id="d2f803a6-9b12-d245-e3bb-79a67a68ba13"
                    style={{ opacity: 0 }}
                    className="w-layout-grid portfolio_gallery_row"
                  >
                    <a
                      href="#"
                      id="w-node-d2f803a6-9b12-d245-e3bb-79a67a68ba14-27f01313"
                      className="portfolio_gallery_lightbox-link w-inline-block w-lightbox"
                    >
                      <div className="portfolio_gallery_image-wrapper">
                        <img
                          src={porfolioImg7}
                          loading="lazy"
                          alt=""
                          className="portfolio_gallery_image"
                        />
                      </div>
                    </a>
                    <a
                      href="#"
                      id="w-node-d2f803a6-9b12-d245-e3bb-79a67a68ba17-27f01313"
                      className="portfolio_gallery_lightbox-link w-inline-block w-lightbox"
                    >
                      <div className="portfolio_gallery_image-wrapper">
                        <img
                          src={porfolioImg8}
                          loading="lazy"
                          alt=""
                          className="portfolio_gallery_image"
                        />
                      </div>
                    </a>
                    <a
                      href="#"
                      id="w-node-d2f803a6-9b12-d245-e3bb-79a67a68ba1a-27f01313"
                      className="portfolio_gallery_lightbox-link w-inline-block w-lightbox"
                    >
                      <div className="portfolio_gallery_image-wrapper">
                        <img
                          src={porfolioImg9}
                          loading="lazy"
                          alt=""
                          className="portfolio_gallery_image"
                        />
                      </div>
                    </a>
                  </div>
                  <div
                    data-w-id="74875b2f-9d2a-6e98-e2ac-7e754b78daeb"
                    style={{ opacity: 0 }}
                    className="w-layout-grid portfolio_gallery_row"
                  >
                    <a
                      href="#"
                      id="w-node-_74875b2f-9d2a-6e98-e2ac-7e754b78daec-27f01313"
                      className="portfolio_gallery_lightbox-link w-inline-block w-lightbox"
                    >
                      <div className="portfolio_gallery_image-wrapper">
                        <img
                          src={porfolioImg10}
                          loading="lazy"
                          alt=""
                          className="portfolio_gallery_image"
                        />
                      </div>
                    </a>
                    <a
                      href="#"
                      id="w-node-_74875b2f-9d2a-6e98-e2ac-7e754b78daef-27f01313"
                      className="portfolio_gallery_lightbox-link w-inline-block w-lightbox"
                    >
                      <div className="portfolio_gallery_image-wrapper">
                        <img
                          src={porfolioImg11}
                          loading="lazy"
                          alt=""
                          className="portfolio_gallery_image"
                        />
                      </div>
                    </a>
                    <a
                      href="#"
                      id="w-node-_74875b2f-9d2a-6e98-e2ac-7e754b78daf2-27f01313"
                      className="portfolio_gallery_lightbox-link w-inline-block w-lightbox"
                    >
                      <div className="portfolio_gallery_image-wrapper">
                        <img
                          src={porfolioImg12}
                          loading="lazy"
                          alt=""
                          className="portfolio_gallery_image"
                        />
                      </div>
                    </a>
                  </div>
                  <div
                    data-w-id="f7f35bf3-6b65-a139-6a4c-9b0e34aa064f"
                    style={{ opacity: 0 }}
                    className="w-layout-grid portfolio_gallery_row"
                  >
                    <a
                      href="#"
                      id="w-node-f7f35bf3-6b65-a139-6a4c-9b0e34aa0650-27f01313"
                      className="portfolio_gallery_lightbox-link w-inline-block w-lightbox"
                    >
                      <div className="portfolio_gallery_image-wrapper">
                        <img
                          src={porfolioImg13}
                          loading="lazy"
                          alt=""
                          className="portfolio_gallery_image"
                        />
                      </div>
                    </a>
                    <a
                      href="#"
                      id="w-node-f7f35bf3-6b65-a139-6a4c-9b0e34aa0653-27f01313"
                      className="portfolio_gallery_lightbox-link w-inline-block w-lightbox"
                    >
                      <div className="portfolio_gallery_image-wrapper">
                        <img
                          src={porfolioImg14}
                          loading="lazy"
                          alt=""
                          className="portfolio_gallery_image"
                        />
                      </div>
                    </a>
                    <a
                      href="#"
                      id="w-node-f7f35bf3-6b65-a139-6a4c-9b0e34aa0656-27f01313"
                      className="portfolio_gallery_lightbox-link w-inline-block w-lightbox"
                    >
                      <div className="portfolio_gallery_image-wrapper">
                        <img
                          src={porfolioImg15}
                          loading="lazy"
                          alt=""
                          className="portfolio_gallery_image"
                        />
                      </div>
                    </a>
                  </div>
                  <div
                    data-w-id="74875b2f-9d2a-6e98-e2ac-7e754b78daeb"
                    style={{ opacity: 0 }}
                    className="w-layout-grid portfolio_gallery_row"
                  >
                    <a
                      href="#"
                      id="w-node-_74875b2f-9d2a-6e98-e2ac-7e754b78daec-27f01313"
                      className="portfolio_gallery_lightbox-link w-inline-block w-lightbox"
                    >
                      <div className="portfolio_gallery_image-wrapper">
                        <img
                          src={porfolioImg16}
                          loading="lazy"
                          alt=""
                          className="portfolio_gallery_image"
                        />
                      </div>
                    </a>
                    <a
                      href="#"
                      id="w-node-_74875b2f-9d2a-6e98-e2ac-7e754b78daef-27f01313"
                      className="portfolio_gallery_lightbox-link w-inline-block w-lightbox"
                    >
                      <div className="portfolio_gallery_image-wrapper">
                        <img
                          src={porfolioImg17}
                          loading="lazy"
                          alt=""
                          className="portfolio_gallery_image"
                        />
                      </div>
                    </a>
                    <a
                      href="#"
                      id="w-node-_74875b2f-9d2a-6e98-e2ac-7e754b78daf2-27f01313"
                      className="portfolio_gallery_lightbox-link w-inline-block w-lightbox"
                    >
                      <div className="portfolio_gallery_image-wrapper">
                        <img
                          src={porfolioImg18}
                          loading="lazy"
                          alt=""
                          className="portfolio_gallery_image"
                        />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          data-w-id="488cecdd-4796-b7af-eb92-976f0ebf1828"
          style={{ opacity: 0 }}
          className="section_portfolio_cta"
        >
          <div className="padding-global background-color-dark">
            <div className="container-large">
              <div className="padding-section-large">
                <div className="w-layout-grid portfolio_cta_component">
                  <div className="portfolio_cta_content-left">
                    <h2 className="color-white">
                      Transform Your Windows with Style
                    </h2>
                  </div>
                  <div className="portfolio_cta_content-right">
                    <p className="color-white text-size-medium">
                      Discover our wide range of window treatment options and
                      create the perfect ambiance for your space.
                    </p>
                    <div className="margin-top margin-medium">
                      <div className="button-group">
                        <a
                          href="/about"
                          className="button is-secondary w-button"
                        >
                          Learn More
                        </a>
                        <a
                          href="/contact"
                          className="button is-link is-icon w-inline-block"
                        >
                          <div className="color-white">Contact</div>
                          <div className="icon-embed-xxsmall w-embed">
                            <svg
                              width={16}
                              height={16}
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6 3L11 8L6 13"
                                stroke="#928686"
                                strokeWidth="1.5"
                              />
                            </svg>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Portfolio;
