import React, { useState, useEffect } from "react";
import servicesHeader from "../Assets/servicesHeader.jpg";
import servicesImg1 from "../Assets/servicesImg1.jpg";
import servicesImg2 from "../Assets/servicesImg2.jpg";
import services1 from "../Assets/services1.png";
import services2 from "../Assets/services2.png";
import services3 from "../Assets/services3.png";

const Services = () => {
  const [navbarHeight, setNavbarHeight] = useState();

  useEffect(() => {
    const navbar = document.getElementsByClassName("navbar2_component")[0];
    setNavbarHeight(navbar.offsetHeight);
  }, []);
  return (
    <div className="page-wrapper" style={{ paddingTop: navbarHeight }}>
      <main className="main-wrapper">
        <header className="section_services_1_header">
          <div className="padding-global">
            <div className="container-large">
              <div className="padding-section-large">
                <div className="max-width-large">
                  <div className="margin-bottom margin-small">
                    <h1 className="color-white">Our Range of Services</h1>
                  </div>
                  <p className="text-size-medium color-white">
                    Explore Our Comprehensive Window Treatment Solutions
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="services_1_header_background-image-wrapper">
            <div className="image-overlay-layer" />
            <img
              src={servicesHeader}
              loading="eager"
              alt=""
              className="services_1_header_background-image"
            />
          </div>
        </header>
        <section className="section_services_2_feature">
          <div className="padding-global">
            <div className="container-large">
              <div className="padding-section-large">
                <div
                  data-w-id="70774762-9c75-12cc-6f32-e26f10f7c1a6"
                  style={{ opacity: 0 }}
                  className="w-layout-grid services_2_feature_component"
                >
                  <div className="services_2_feature_content">
                    <div className="margin-bottom margin-small">
                      <h3 className="color-dark">
                        Discover Tailored Solutions for Your Space
                      </h3>
                    </div>
                    <p className="color-dark text-size-medium">
                      At Stylish Deco Design, we understand the importance of
                      selecting the perfect solutions to enhance both the beauty
                      and functionality of your space. Our consultation process
                      is designed to collaborate closely with clients, ensuring
                      we find the ideal fit for their unique preferences and
                      style. From curtains and blinds to shades, drapes, and
                      elegant plantation shutters, we offer a wide range of
                      options to transform your living spaces into captivating
                      focal points. Let our skilled team guide you in making
                      selections that authentically mirror your personal taste
                      and lifestyle, all while incorporating premium Australian
                      materials.
                    </p>
                  </div>
                  {window.innerWidth > 768 && (
                    <div className="services_2_feature_image-wrapper">
                      <img
                        src={servicesImg1}
                        loading="lazy"
                        alt=""
                        className="services_2_feature_image"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section_services_3_features-list">
          <div className="padding-global">
            <div className="container-large">
              <div className="padding-section-large">
                <div
                  data-duration-in={400}
                  data-duration-out={200}
                  data-current="Tab 1"
                  data-easing="ease"
                  className="services_3_features-list_component w-tabs"
                >
                  <div
                    id="w-node-_70774762-9c75-12cc-6f32-e26f10f7c1b4-a51b41b4"
                    data-w-id="70774762-9c75-12cc-6f32-e26f10f7c1b4"
                    style={{ opacity: 0 }}
                    className="services_3_features-list_tabs-menu w-tab-menu"
                  >
                    <a
                      data-w-tab="Tab 1"
                      className="services_3_features-list_tabs-link w-inline-block w-tab-link w--current"
                    >
                      <div className="margin-bottom margin-xsmall">
                        <h3 className="color-dark heading-style-h4">
                          Our Services Include:
                        </h3>
                      </div>
                      <p className="color-dark">
                        We offer a comprehensive range of services including
                        design consultation, precise measurement, professional
                        installation, and dedicated after-sales support. Our
                        team of experts will guide you through every step of the
                        process to ensure that your window treatments are
                        tailored to your unique style and requirements.
                      </p>
                    </a>
                    <a
                      data-w-tab="Tab 2"
                      className="services_3_features-list_tabs-link w-inline-block w-tab-link"
                    >
                      <div className="margin-bottom margin-xsmall">
                        <h3 className="color-dark heading-style-h4">
                          Design Consultation
                        </h3>
                      </div>
                      <p className="color-dark">
                        Our experienced designers will work closely with you to
                        understand your vision and provide personalized
                        recommendations that suit your aesthetic preferences and
                        functional needs.
                      </p>
                    </a>
                    <a
                      data-w-tab="Tab 3"
                      className="services_3_features-list_tabs-link w-inline-block w-tab-link"
                    >
                      <div className="margin-bottom margin-xsmall">
                        <h3 className="color-dark heading-style-h4">
                          Measurement &amp; Installation
                        </h3>
                      </div>
                      <p className="color-dark">
                        Our skilled technicians will take precise measurements
                        and ensure the seamless installation of your chosen
                        window treatments, guaranteeing a perfect fit and
                        flawless finish.
                      </p>
                    </a>
                  </div>
                  <div className="w-tab-content">
                    <div
                      data-w-tab="Tab 1"
                      className="w-tab-pane w--tab-active"
                    >
                      {window.innerWidth > 768 && (
                        <div className="services_3_features-list_image-wrapper">
                          <img
                            src={servicesImg2}
                            loading="lazy"
                            style={{ opacity: 0 }}
                            data-w-id="70774762-9c75-12cc-6f32-e26f10f7c1ca"
                            alt=""
                            className="services_3_features-list_image"
                          />
                        </div>
                      )}
                    </div>
                    <div data-w-tab="Tab 2" className="w-tab-pane">
                      {window.innerWidth > 768 && (
                        <div className="services_3_features-list_image-wrapper">
                          <img
                            src={servicesImg2}
                            loading="lazy"
                            style={{ opacity: 0 }}
                            data-w-id="70774762-9c75-12cc-6f32-e26f10f7c1ca"
                            alt=""
                            className="services_3_features-list_image"
                          />
                        </div>
                      )}
                    </div>
                    <div data-w-tab="Tab 3" className="w-tab-pane">
                      {window.innerWidth > 768 && (
                        <div className="services_3_features-list_image-wrapper">
                          <img
                            src={servicesImg2}
                            loading="lazy"
                            style={{ opacity: 0 }}
                            data-w-id="70774762-9c75-12cc-6f32-e26f10f7c1ca"
                            alt=""
                            className="services_3_features-list_image"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section_services_4_features-list background-color-dark">
          <div className="padding-global">
            <div className="container-large">
              <div
                data-w-id="70774762-9c75-12cc-6f32-e26f10f7c1d6"
                style={{ opacity: 0 }}
                className="padding-section-large"
              >
                <div className="services_4_features-list_component ">
                  <div className="margin-bottom margin-xxlarge">
                    <div className="text-align-center">
                      <div className="max-width-large">
                        <h3 className="color-white">
                          Discover a Wide Range of Window Treatment Options
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="w-layout-grid services_4_features-list_list">
                    <div className="services_4_features-list_item">
                      <div className="margin-bottom margin-small">
                        <img
                          src={services1}
                          loading="lazy"
                          alt=""
                          className="icon-1x1-medium"
                        />
                      </div>
                      <div className="margin-bottom margin-small">
                        <h3 className="color-white heading-style-h5">
                          Custom Curtain Creations
                        </h3>
                      </div>
                      <p className="color-white">
                        Elevate your interior with our bespoke curtain designs.
                        Choose from a wide array of luxurious fabrics, colors,
                        and styles, and let us create stunning curtains that
                        perfectly match your decor.
                      </p>
                    </div>
                    <div className="services_4_features-list_item">
                      <div className="margin-bottom margin-small">
                        <img
                          src={services2}
                          loading="lazy"
                          alt=""
                          className="icon-1x1-medium"
                        />
                      </div>
                      <div className="margin-bottom margin-small">
                        <h3 className="color-white heading-style-h5">
                          Shutter Design
                        </h3>
                      </div>
                      <p className="color-white">
                        Elevate your windows with expert shutter installation.
                        Our skilled team will help you choose the perfect
                        shutters for your space, ensuring a seamless and stylish
                        integration that complements your decor.
                      </p>
                    </div>
                    <div className="services_4_features-list_item">
                      <div className="margin-bottom margin-small">
                        <img
                          src={services3}
                          loading="lazy"
                          alt=""
                          className="icon-1x1-medium"
                        />
                      </div>
                      <div className="margin-bottom margin-small">
                        <h3 className="color-white heading-style-h5">
                          Blinds & Shades Expertise
                        </h3>
                      </div>
                      <p className="color-white">
                        Experience the perfect blend of form and function with
                        our blinds and shades solutions. Our expert team will
                        help you select the ideal window coverings for privacy,
                        light control, and style.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          data-w-id="70774762-9c75-12cc-6f32-e26f10f7c208"
          style={{ opacity: 0 }}
          className="section_services_5_cta"
        >
          <div className="padding-global">
            <div className="container-large">
              <div className="padding-section-large">
                <div className="w-layout-grid services_5_cta_component">
                  <div className="services_5_cta_content-left">
                    <h2 className="color-dark">
                      Transform Your Windows with Style
                    </h2>
                  </div>
                  <div className="services_5_cta_content-right">
                    <p className="color-dark text-size-medium">
                      At Stylish Deco Design, we offer expert window treatment
                      solutions tailored to your needs. Whether you're looking
                      for curtains, blinds, shades, drapes, or shutters, we have
                      the perfect options to enhance the beauty and
                      functionality of your windows.
                    </p>
                    <div className="margin-top margin-medium">
                      <div className="button-group">
                        <a href="/contact" className="button w-button">
                          Contact
                        </a>
                        <a
                          href="/about"
                          className="button is-secondary w-button"
                        >
                          Learn More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Services;
