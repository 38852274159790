import React from "react";

const Privacy = () => {
  return (
    <section className="section_faq7">
      <div className="padding-global color-dark">
        <div className="container-small">
          <div className="padding-section-large">
            <div className="margin-bottom margin-xxlarge">
              <div className="text-align-center">
                <div className="max-width-large">
                  <h2>Privacy Policy</h2>
                </div>
              </div>
            </div>
            <div className="div-block">
              <div className="text-block">
                Welcome to Importex's Privacy Policy. At Importex, we are
                committed to safeguarding your privacy. This Privacy Policy
                outlines how we collect, use, disclose, and protect your
                personal information when you interact with our website or
                utilize our services. By accessing or using our website, you
                consent to the practices described in this policy.
              </div>
            </div>
            <div className="faq7_component">
              <div className="faq7_item">
                <div className="margin-bottom margin-xsmall">
                  <div className="text-size-medium text-weight-bold">
                    <strong>Information We Collect</strong>
                  </div>
                </div>
                <p>
                  We want to reassure you that when you visit our website, we do
                  not collect any personal information from you. You can
                  navigate our site without concerns about your privacy being
                  compromised.
                </p>
              </div>
              <div className="faq7_item">
                <div className="margin-bottom margin-xsmall">
                  <div className="text-size-medium text-weight-bold">
                    <strong>External Links</strong>
                  </div>
                </div>
                <p>
                  Please be aware that our website may contain links to external
                  websites. While we strive to provide links to reputable
                  sources, we do not have control over the privacy practices of
                  these external sites. Therefore, we encourage you to review
                  the privacy policies of any website you visit through our
                  links.
                </p>
              </div>
              <div className="faq7_item">
                <div className="margin-bottom margin-xsmall">
                  <div className="text-size-medium text-weight-bold">
                    <strong>Changes to this Privacy Policy</strong>
                  </div>
                </div>
                <p>
                  Although we do not collect personal information, we may update
                  this Privacy Policy to reflect any changes in our practices or
                  for legal and regulatory reasons. We encourage you to
                  periodically review this policy for any updates.
                </p>
                <div className="div-block ">
                  <div>
                    If you have any questions or concerns regarding your privacy
                    while using our website, please do not hesitate to contact
                    us. Your privacy matters to us, and we are committed to
                    ensuring a safe and secure browsing experience for all our
                    visitors.
                  </div>
                </div>
              </div>
            </div>
            <div className="margin-top margin-xxlarge">
              <div className="text-align-center">
                <div className="max-width-medium align-center">
                  <div className="margin-bottom margin-xsmall">
                    <h4 className="color-dark">Still have questions?</h4>
                  </div>
                  <p className="color-dark text-size-medium">
                    Contact us for further assistance.
                  </p>
                  <div className="margin-top margin-medium">
                    <a
                      href="/contact"
                      className="color-dark button is-secondary w-button"
                    >
                      Contact
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Privacy;
